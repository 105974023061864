define("discourse/plugins/discourse-unhandled-tagger/templates/connectors/topic-footer-main-buttons-before-create/handled-button", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showHandled}}
    {{#if handled}}
      {{d-button
        class="unhandle"
        icon="times-circle"
        action=(action "setUnhandled" true)
        label="unhandled_tagger.unhandle.title"
        title="unhandled_tagger.unhandle.help"
      }}
    {{else}}
      {{d-button
        class="handle"
        icon="check-circle"
        action=(action "setUnhandled" false)
        label="unhandled_tagger.handled.title"
        title="unhandled_tagger.handled.help"
      }}
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "O3h+9ilo",
    "block": "[[[41,[30,0,[\"showHandled\"]],[[[41,[30,0,[\"handled\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"class\",\"icon\",\"action\",\"label\",\"title\"],[\"unhandle\",\"times-circle\",[28,[37,2],[[30,0],\"setUnhandled\",true],null],\"unhandled_tagger.unhandle.title\",\"unhandled_tagger.unhandle.help\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"class\",\"icon\",\"action\",\"label\",\"title\"],[\"handle\",\"check-circle\",[28,[37,2],[[30,0],\"setUnhandled\",false],null],\"unhandled_tagger.handled.title\",\"unhandled_tagger.handled.help\"]]]],[1,\"\\n\"]],[]]]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showHandled` property path was used in the `discourse/plugins/discourse-unhandled-tagger/templates/connectors/topic-footer-main-buttons-before-create/handled-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showHandled}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `handled` property path was used in the `discourse/plugins/discourse-unhandled-tagger/templates/connectors/topic-footer-main-buttons-before-create/handled-button.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.handled}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-unhandled-tagger/templates/connectors/topic-footer-main-buttons-before-create/handled-button.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});